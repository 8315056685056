/** 現在のユーザー(currentUser)を管理するatom
 **/
import { gql, useFragment } from "@/scripts/generated"
import { CurrentUserFragmentDoc, GetCurrentUserQueryDocument, type UserType } from "@/scripts/generated/graphql"
import type { ResultOf } from "@graphql-typed-document-node/core"
import { atom, getDefaultStore, useAtomValue } from "jotai"
import { atomWithRefresh } from "jotai/utils"
import { gqlClient } from "../networking/graphql-client"
import { rememberLastLogin } from "./remember"

gql(/* GraphQL */ `
  query GetCurrentUserQuery {
    me {
      __typename
      user {
        __typename
        email
        ...CurrentUser
      }
    }
  }
`)

gql(/** GraphQL */ `
  fragment CurrentUser on User {
    id
    name
    company_name
    permission

    groups {
      name
      id
      yodobashi_application
    }

    client {
      id
    }
  }
`)

// urqlによって監視されていないため、再取得はatomWithRefreshを使う
const currentUserQueryAtom2 = atomWithRefresh(async () => {
	const ret = await gqlClient
		.query(
			GetCurrentUserQueryDocument,
			{},
			{
				requestPolicy: "cache-first",
			}
		)
		.toPromise()

	if (ret.data?.me?.user) {
		rememberLastLogin(ret.data.me.user.email)
	}
	return ret.data
})

const forceStateAtom = atom<null | { type: "LOGOUT" }>(null)

export type CurrentUserAction = {
	type: "FORCE_LOGOUT" | "RESET"
}

const currentUserQueryOrNull = atom(
	async (get): Promise<UseCurrentUserFetchedState> => {
		if (get(forceStateAtom)?.type === "LOGOUT") {
			return GuestUser
		}
		const data = await get(currentUserQueryAtom2)
		if (!data?.me) {
			// ログアウトされている
			return GuestUser
		}

		const user = useFragment(CurrentUserFragmentDoc, data?.me.user)

		return [user, user.permission]
	},
	(_get, set, update: CurrentUserAction) => {
		if (update.type === "FORCE_LOGOUT") {
			set(forceStateAtom, (current) => (!current ? { type: "LOGOUT" } : current))
		} else if (update.type === "RESET") {
			set(forceStateAtom, (current) => (current ? null : current))
			set(currentUserQueryAtom2)
		}
	}
)

const GuestUser = [
	{
		id: "",
		company_name: "",
		groups: [],
		name: "",
	},
	null,
] as const satisfies UseCurrentUserFetchedState

type UseCurrentUserFetchedState = [user: ResultOf<typeof CurrentUserFragmentDoc>, permission?: UserType | null]

export const setUnauthenticated = (action: CurrentUserAction) => {
	return getDefaultStore().set(currentUserQueryOrNull, action)
}

/** 現在のユーザーを返します */
export const useCurrentUser = () => useAtomValue(currentUserQueryOrNull)
