import { routeTree } from "@/scripts/generated/routes.gen"
import "@/scripts/lib/valibot"
import { LoadingOverlay, MantineProvider, createEmotionCache } from "@mantine/core"
import * as Sentry from "@sentry/react"
import { RouterProvider, createRouter } from "@tanstack/react-router"
import "dayjs/locale/ja"
import type React from "react"
import { HelmetProvider } from "react-helmet-async"
import { ErrorFallback } from "../components/common-ui/error-fallback"
import { THEME } from "../theme"

const router = createRouter({
	routeTree,
	basepath: "/v2",

	// 読み込み中表示
	// デフォルトで 1000ms 待ってから表示(peningMs)
	// 最小表示時間は 500ms (pendingMinMs)
	defaultPendingComponent: () => <LoadingOverlay visible />,
})

// Register the router instance for type safety
declare module "@tanstack/react-router" {
	interface Register {
		router: typeof router
	}
}

const emotionCache = createEmotionCache({ key: "tm" })

const UIProvider = (props: { children?: React.ReactNode }) => (
	<HelmetProvider>
		<MantineProvider
			theme={THEME}
			withCSSVariables
			// NOTE: React化できたらこちらを使用する
			// withNormalizeCSS
			// withGlobalStyles
			emotionCache={emotionCache}
		>
			{props.children}
		</MantineProvider>
	</HelmetProvider>
)

export const App = () => {
	return (
		<Sentry.ErrorBoundary fallback={ErrorFallback}>
			<UIProvider>
				<RouterProvider router={router} />
			</UIProvider>
		</Sentry.ErrorBoundary>
	)
}
